import { Text as T } from "theme-ui"
import React from "react"

export default function Text({ children, align, as }) {
  return (
    <T
      as={as}
      sx={{
        color: "white",
        textShadow: "neon",
        fontWeight: "thin",
        textAlign: align ?? "center",
      }}
    >
      {children}
    </T>
  )
}
