import Text from "../../components/Atomic/Text"
import PageStructure from "../../components/PageStructure"

import React from "react"

export default function Games({ location }) {
  return (
    <PageStructure
      metaContent={`List of games that I have been involved in, including game jams`}
      pageTitle={`Games`}
      location={location}
    >
      <Text sx={{ color: "greyScale.1" }}>No games to show yet :(</Text>
    </PageStructure>
  )
}
